$header-background-color: #3E1568;
$link-color: #9261ad;

app-root.program-group-tnt {
  app-header {
    background-color: $header-background-color;
    border-bottom: 4px solid darken($header-background-color, 10%);
  }

  a {
    color: $link-color;

    &:hover {
      color: darken($link-color, 10%);
      text-decoration: underline;
    }
  }

  .btn-primary {
    background-color: #97D700;
    border-color: #ced4da;
    color: #3D382C;
  }

  .btn:not([disabled]).active, .btn:not([disabled]):active, .btn:not([disabled]):hover, .btn:not([disabled]):focus {
    background-color: #97D700 !important;
    border-color: #ced4da !important;
    color: #3D382C !important;
  }

  ul.stepper {
    li.active {
      span.step-title {
        .circle {
          &.current {
            background-color: #97D700;
            color: #3D382C;
          }
        }
      }
    }
  }

  tr.is-selected {
    background-color: #97D700;
  }

  tbody tr:hover:not(.is-selected), pagination-controls li.current {
    background-color: #d3f485 !important;
  }
}
