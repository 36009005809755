$header-background-color: #05182C;
$link-color: #1c3f95;

app-root.program-group-ltn {
  app-header {
    background-color: $header-background-color;
    border-bottom: 4px solid darken($header-background-color, 10%);
  }

  a {
    color: $link-color;
  }
}

.fundraise-link {
  font-weight: bold;
  text-decoration: underline;
}
