$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 740px,
  xl: 740px
) !default;

@import '~bootstrap/scss/bootstrap.scss';
@import './typography.scss';
@import './ltn.scss';
@import './tnt.scss';
@import './mwoy.scss';

.btn {
  border-radius: 6px;
  box-shadow: none !important;
  font-size: 18px;
  font-weight: 300;
  outline: none;
  padding: 0.675rem 1.2rem;

  &:not([disabled]) {
    &.active,
    &:hover,
    &:focus,
    &:active {
      background-color: #febd0f !important;
      border-color: #febd0f !important;
      color: #3D382C !important;
    }
  }
}

.btn-primary {
  background-color: #FECB42;
  border-color: #fec019;
  color: #3D382C;
}

.btn-secondary {
  color: #656565;
  background-color: #eee;
  border: 1px solid #dadada;
}

.form-control {
  height: calc(2.2em + .75rem + 2px);
  padding: .675rem .75rem;

  &:focus {
    border: 1px solid #ced4da;
    box-shadow: none;
  }

  &.ng-touched {
    &.ng-invalid {
      border-color: #cb0000;
    }

    &.ng-valid {
      border-color: #3eb16d;

      background-position: calc(100% - 20px) center;
      padding-right: 2.3em;
      background-image: url(data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20width%3D%2215.999px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2015.999%2016%22%20enable-background%3D%22new%200%200%2015.999%2016%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%233EB16E%22%20d%3D%22M8%2C0C3.581%2C0%2C0%2C3.582%2C0%2C8c0%2C4.418%2C3.581%2C8%2C8%2C8c4.418%2C0%2C7.999-3.582%2C7.999-8S12.418%2C0%2C8%2C0z%20M8%2C14.5%20c-3.59%2C0-6.5-2.91-6.5-6.5c0-3.59%2C2.91-6.5%2C6.5-6.5s6.499%2C2.91%2C6.499%2C6.5C14.499%2C11.59%2C11.59%2C14.5%2C8%2C14.5z%20M11.144%2C4.597%20c-0.34-0.203-0.774-0.083-0.97%2C0.27L7.491%2C9.686L5.997%2C7.867c-0.271-0.312-0.63-0.473-0.969-0.27C4.688%2C7.8%2C4.548%2C8.29%2C4.768%2C8.604%20l2.079%2C2.531c0.155%2C0.201%2C0.333%2C0.322%2C0.519%2C0.357l0.001%2C0.002l0.02%2C0.002c0.042%2C0.006%2C0.67%2C0.122%2C0.94-0.361l3.079-5.531%20C11.601%2C5.251%2C11.483%2C4.8%2C11.144%2C4.597z%22%2F%3E%3C%2Fsvg%3E);
      background-repeat: no-repeat;
    }
  }
}

form {
  &.ng-invalid.ng-touched {
    .invalid-feedback {
      display: block;
    }
  }
}

button.btn:disabled {
  background-color: #cccccc;
  border-color: #999999;
  color: #808080;
  opacity: 1;

  &:hover {
    cursor: pointer;
  }
}

.modal-dialog {
  width: 700px;
}

.input-annotation {
  font-style: italic;
  font-size: smaller;
}

.modal-content {
  border: none;
}

@media only screen and (max-width: 767px) {
  .modal-dialog {
    max-width: 100%;
    width: auto;
    margin: 0;
  }
}

@media only screen and (max-width: 800px) {
  .btn {
    font-size: 16px;
    padding: 0.375rem 0.70rem;
  }
}

table {
  td {
    word-break: break-word;
  }
}

@media (min-width: 1200px) {
  .lls-container {
    max-width: 1024px;
  }
}

.toast-container.toast-top-center .ngx-toastr.ngx-toastr-big {
  width: 800px;
}

// Missing ./node_modules/bootstrap/scss/utilities/_spacing.scss
// stylelint-disable declaration-no-important

// Margin and Padding

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }
        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
        }
      }
    }

    // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
    @each $size, $length in $spacers {
      @if $size != 0 {
        .m#{$infix}-n#{$size} { margin: -$length !important; }
        .mt#{$infix}-n#{$size},
        .my#{$infix}-n#{$size} {
          margin-top: -$length !important;
        }
        .mr#{$infix}-n#{$size},
        .mx#{$infix}-n#{$size} {
          margin-right: -$length !important;
        }
        .mb#{$infix}-n#{$size},
        .my#{$infix}-n#{$size} {
          margin-bottom: -$length !important;
        }
        .ml#{$infix}-n#{$size},
        .mx#{$infix}-n#{$size} {
          margin-left: -$length !important;
        }
      }
    }

    // Some special margin utils
    .m#{$infix}-auto { margin: auto !important; }
    .mt#{$infix}-auto,
    .my#{$infix}-auto {
      margin-top: auto !important;
    }
    .mr#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-right: auto !important;
    }
    .mb#{$infix}-auto,
    .my#{$infix}-auto {
      margin-bottom: auto !important;
    }
    .ml#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-left: auto !important;
    }
  }
}

// missing classes in bootstrap 5 used by the app
@import "bootstrap-5-fixes";
