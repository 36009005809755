$header-background-color: #555;
$link-color: #1c3f95;

app-root.program-group-mwoy {
  app-header {
    background-color: $header-background-color;
    border-bottom: 4px solid darken($header-background-color, 10%);

    img {
      width: auto;
    }
  }

  a {
    color: $link-color;
  }
}
