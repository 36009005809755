body {
  font-family: "proxima-nova", arial, "helvetica neue", helvetica, sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: #525252;
}

h3 {
  font-weight: 600;
  margin-bottom: 0.35em;
  line-height: 1.2;
  color: #333333;
  font-size: 1.25em;
}

h4 {
  font-weight: 600;
  margin-bottom: 0.35em;
  line-height: 1.2;
  color: #333333;
  font-size: 1em;
}

.medium-fontsize {
  font-size: 1rem;
}

@media only screen and (max-width: 800px) {
  body {
    font-size: 16px;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.112em;
  }
}
